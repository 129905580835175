// Feature flags key management - important to update as new flags are added
export enum FeatureFlagKeys {
  testFeatureFlag = 'test-feature-flag',
  playbookAssignments = 'playbook-assignments',
  enterpriseResourcesUpload = 'enterprise-resources-upload',
  chatbot = 'chatbot-ui',
}

export enum ProductType {
  enterprisePlaybook = 'enterprise-playbook',
  playbook = 'playbook',
  chat = 'chat-product',
  personas = 'personas-product',
  promptLab = 'promptlab-product',
}

export enum userRegistration {
  sso = 'sso',
  password = 'password',
  both = 'both',
}

export enum UserTypes {
  all = 'all',
  platform = 'platform',
  client = 'client',
}

export enum clientSettingsSidebarSections {
  whiteLabeling = 'whiteLabeling',
  userRegistration = 'userRegistration',
  uploadResources = 'uploadResources',
  deactivateClient = 'deactivateClient',
}

export enum ClientStatus {
  active = 'active',
  deactivated = 'deactivated',
}

export enum UserStatus {
  active = 'active',
  deactivated = 'deactivated',
}

export enum ProductFeature {
  ventureBuilder = 'venture_builder_feature',
  contactExpert = 'contact_expert_feature',
  assignContent = 'assign_content_feature',
}

export enum OnlinePeopleStatus {
  online = 'online',
  offline = 'offline',
}

export enum NotificationType {
  web = 'web',
  email = 'email',
}

export enum Theme {
  light = 'light',
  dark = 'dark',
}

export enum ProjectResourceTypes {
  project = 'Projects',
  airtable = 'Airtable',
  miro = 'Miro',
  keynote = 'Keynote',
  googleDocs = 'Google Doc',
  googleDrive = 'Google Drive',
  googleSheet = 'Google Sheet',
  googleSlides = 'Google Slides',
  googleResources = 'GoogleResources',
}

export enum ResourceTypes {
  MIRO = 'Miro',
  GOOGLE_DOC = 'Google Doc',
  GOOGLE_SHEET = 'Google Sheet',
  GOOGLE_SLIDE = 'Google Slides',
  GOOGLE_DRIVE = 'Google Drive',
  AIRTABLE = 'Airtable',
  GOOGLE_RESOURCES = 'Google Resources',
}

export enum EnterpriseResourceTypes {
  PDF = 'PDF',
  WORD = 'Word Document',
  POWERPOINT = 'Powerpoint Document',
  EXCEL = 'Excel Document',
}

export enum VentureBuilderItemAction {
  VIEW = 'view',
  ADD = 'add',
  EDIT = 'edit',
  DELETE = 'delete',
}

export enum MarketSizeKeys {
  TAM = 'tam',
  SAM = 'sam',
  SOM = 'som',
}

export enum Currencies {
  USD = 'USD',
  EUR = 'EUR',
  JPY = 'JPY',
  GBP = 'GBP',
}

export enum SanityPopoverTypeEnum {
  introduction = 'introduction',
  closing = 'closing',
}

export enum incubatePlaybookTaskEnum {
  taskGeneral = 'taskGeneral',
  taskIntroduction = 'taskIntroduction',
  taskConcept = 'taskConcept',
  taskActivity = 'taskActivity',
  taskQuiz = 'taskQuiz',
  taskSummary = 'taskSummary',
}
